<template>
    <div class="search">
        <div class="bigImg bgimg" style="backgroundimage:url('../../assets/img/skin/bigimg1.jpg'); ">
            <!-- <img src="../../assets/img/skin/bigimg1.jpg" alt=""> -->
        </div>
<div class="main fixed">
    <div class="left wow fadeInLeft animated" style="visibility: visible; animation-name: fadeInLeft;">
          <div class="sideMenu">
        <div class="sideTit"><a href="javascript:;">
          <h3>搜索结果</h3>
          <p>search</p>
          </a></div>
        <div class="mobileTit"><a>搜索结果</a></div>
        <ul class="sideMenuNav">
            </ul>
      </div>
        <app-config></app-config>
        </div>
    <!--left end-->
    
    <div class="right wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
          <div class="path">
        <h3>搜索 <strong class="fc_03c"></strong> 的结果</h3>
        <div class="pathdiv"> </div>
      </div>
          <ul class="downLoad newsList">
        <!-- <li><a href="/a/zuzhijiagou/gongshanglianlingdao/231.html"><span>2018-01-02</span>
          <div>符耗 省工商联主席、省总</div>
          </a></li><li><a href="/a/zhuantilanmu/xinxigongkai/206.html"><span>2018-01-02</span>
          <div>省工商联副<font color="red">会长</font>企业海王</div>
          </a></li><li><a href="/a/zhuantilanmu/xinxigongkai/201.html"><span>2018-01-02</span>
          <div>国家工商总局领导到肇庆</div>
          </a></li><li><a href="/a/zhuantilanmu/jinrongfuwu/187.html"><span>2018-01-02</span>
          <div>省总商会副<font color="red">会长</font>企业维达</div>
          </a></li> -->
      </ul>
          <!-- <div class="paged"> 共1页/4条记录 </div> -->
        </div>
    <!--right end--> 
    
  </div>
    </div>
</template>

<script>
import AppConfig from "@/components/ahshconfig"

    export default {
         components:{
            AppConfig
        },
        data() {
            return {
                key: ''
            }
        },
    }
</script>

<style lang="less" scoped>
    .search{
        .bgimg{
            background-image:url('../../assets/img/skin/bigimg3.jpg');
        }
    }
</style>